// !!!! - DO NOT MODIFY - !!!!
// THIS FILE IS DEPLOYED AUTOMATICALLY BY MATTv2.
//
//  Deployment info:
//    Updated at:     2023-11-24T10:36:37+00:00
//    Cause:          Manual deployment.
//    Deployment ID:  176192
//    Project:        546
//    Environment:    4936 - support/multiadd-cleanup
//    MD5:            a20a348d1dff7b8eb5dc7a31a91f79b0
//    Signature:      eyJpdiI6IjZoRDVNTDhhNGl2cEdadUkyY05KTkE9PSIsInZhbHVlIjoia1c4OHUxbFZhQmNIRENpSXFXb25BMzZiSjYzNVJ5S2VlNWdrMmVZOTh2bDdxcG9vejRoek9HZG9GS0hYQzgyZ2crNXdsSXB0Z2hqeFdqR3FVWFNWbDNNYlhYU0E5TXQ2Vjk2cExwOWhFNGpBbDV4NGxDZFwvT0x6V0tQQm84eHdQWGM3ajd3Nzd0Mm5HV2NCM1NLeDJTSkwwS21udnQwSmVGWGhEVlZoRWJJdzZnNTduV0VSRFJORWtmVXdhZTU0cXNvZitmVnNKV0FIVWlUWmljVlhWUVZCc0VZT0Uwc3lUaDdsMVFTOU53aGgwVEVuUkJuNVlQdFVxRERUZkhUS3lIQTVwa2ZTWUtJTXZZZEZCaDR1XC9zTjJwNUpFVUxOMmhrcE9BZ1VndDNxYWRFM2RiQm04QU5ZUWtGNGZOdjRkM3BnSDZTSkJJUUl0UnpURVg0dGFEUThCTzlUdXNwUU1NYkljSW9tY1hETHM1UElRSFdKNUYrb2V2cmZzMmlpcXlqU1Q5VnJsT1EwVCtMN2xtVzBVbkFnPT0iLCJtYWMiOiI3ZDE5MWNmODIyOWI5YmUzMTNhYTNiNzQ1YzM5ODZmZDY5MzYxOTI5ZTM1N2I0M2Y4ODMxZjE0NjAyY2Q5YjI2In0=
class DetailsModal extends HTMLElement {
  constructor() {
    super();
    this.detailsContainer = this.querySelector('details');
    this.summaryToggle = this.querySelector('summary');

    this.detailsContainer.addEventListener(
      'keyup',
      (event) => event.code.toUpperCase() === 'ESCAPE' && this.close()
    );
    this.summaryToggle.addEventListener(
      'click',
      this.onSummaryClick.bind(this)
    );
    this.querySelector('button[type="button"]').addEventListener(
      'click',
      this.close.bind(this)
    );

    this.summaryToggle.setAttribute('role', 'button');
  }

  isOpen() {
    return this.detailsContainer.hasAttribute('open');
  }

  onSummaryClick(event) {
    event.preventDefault();
    event.target.closest('details').hasAttribute('open')
      ? this.close()
      : this.open(event);
  }

  onBodyClick(event) {
    if (!this.contains(event.target) || event.target.classList.contains('modal-overlay')) this.close(false);
  }

  open(event) {
    this.onBodyClickEvent =
      this.onBodyClickEvent || this.onBodyClick.bind(this);
    event.target.closest('details').setAttribute('open', true);
    document.body.addEventListener('click', this.onBodyClickEvent);
    document.documentElement.classList.add('locked-scroll');

    trapFocus(
      this.detailsContainer.querySelector('[tabindex="-1"]'),
      this.detailsContainer.querySelector('input:not([type="hidden"])')
    );
  }

  close(focusToggle = true) {
    removeTrapFocus(focusToggle ? this.summaryToggle : null);
    this.detailsContainer.removeAttribute('open');
    document.body.removeEventListener('click', this.onBodyClickEvent);
    document.documentElement.classList.remove('locked-scroll');
  }
}

customElements.define('details-modal', DetailsModal);
